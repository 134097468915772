module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-H3VXW85YLV"],"pluginConfig":{"head":false,"respectDNT":true},"gtagConfig":{"anonymize_ip":true,"consentMode":true,"consent":{"ad_storage":"denied","analytics_storage":"denied","ad_personalization":"denied","ad_user_data":"denied"}},"exclude":["/search/","/contact/","/author/**","/designer/**","/magazine/**","/magazine-issues","/category/**","/about-us/","/join-us/","/staff-listing/","/donate-and-subscribe/","/write-for-us/","/writing-resources/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby","short_name":"bsr","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/bsr-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3a8e839682368447a1282e09fcad5e2c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#003262","showSpinner":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
