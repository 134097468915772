// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-issues-js": () => import("./../../../src/pages/magazine-issues.js" /* webpackChunkName: "component---src-pages-magazine-issues-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-designer-js": () => import("./../../../src/templates/designer.js" /* webpackChunkName: "component---src-templates-designer-js" */),
  "component---src-templates-issue-js": () => import("./../../../src/templates/issue.js" /* webpackChunkName: "component---src-templates-issue-js" */),
  "component---src-templates-join-js": () => import("./../../../src/templates/join.js" /* webpackChunkName: "component---src-templates-join-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-staff-js": () => import("./../../../src/templates/staff.js" /* webpackChunkName: "component---src-templates-staff-js" */),
  "component---src-templates-subscribe-js": () => import("./../../../src/templates/subscribe.js" /* webpackChunkName: "component---src-templates-subscribe-js" */),
  "component---src-templates-write-js": () => import("./../../../src/templates/write.js" /* webpackChunkName: "component---src-templates-write-js" */)
}

